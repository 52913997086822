import React from "react";
import { graphql } from "gatsby";
import PageWithCmsWrapper from "../components/shared/page-with-cms-wrapper";

const Privacy = ({
                   path,
                   data: {
                     strapiPrivacy: {
                       title,
                       content,
                       SeoTitle,
                       MetaDescriptions,
                     },
                   },
                 }) => {

  return (
      <PageWithCmsWrapper
          path={path}
          title={title}
          content={content}
          seoProps={{ title: SeoTitle, description: MetaDescriptions}}
      />
  );
};

export default Privacy;

export const query = graphql`
  query PrivacyQuery {
    strapiPrivacy {
      title
      content
      SeoTitle
      MetaDescriptions
    }
  }
`;
